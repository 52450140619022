

import React, { useState } from 'react';
import classes from './HideShowToggle.module.css';

import plus from '../../asset/plus-circle.png';
import minus from '../../asset/minus-icon.png';


const HideShowToggle = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleToggle = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };


  return (
    <div className={classes.component_parent}>
      {data.map((item, index) => (
        <ToggleComponent
          key={index}
          heading={item.heading}
          description={item.description}
          index={index}
          isActive={activeIndex === index}
          onClick={handleToggle}
        />
      ))}
    </div>
  );
};


const ToggleComponent = ({ heading, description, index, isActive, onClick }) => {
  return (
    <div className={classes.component_container}>
      <div className={classes.heading_container}>
        <h3 className={classes.heading_heading}>{heading}</h3>
        <button onClick={() => onClick(index)} className={classes.toggle_button}>
          {isActive ?
            <img className={classes.plus_minus} src={minus} alt="minus" /> :
            <img className={classes.plus_minus} src={plus} alt="plus" />}
        </button>
      </div>
      {isActive && <p className={classes.description}>{description}</p>}
      <hr style={{ color: "#00000040" }} />
    </div>
  );
};




export default HideShowToggle;

