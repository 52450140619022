import React from 'react';
import classes from './Component21s.module.css';

const Component21s = () => {
    const image1 = "https://res.cloudinary.com/dwevyyuwo/image/upload/v1736504890/voupwbmjb383byzxk9gs.avif";
    return (
        <div className={classes.parent}>
            <img src={image1} alt="mobile" />
            <div className={classes.child}>
                <h4 style={{ color: "#07005B" }}>Standard</h4>
                <p>Unlock the future of finance with Rival's Standard Plan for just $6/month! <span>Dive into the world
                    of seamless payments with a card that makes spending as easy as cash. Say goodbye to complicated
                    processes and hello to secure, hassle-free transactions designed to fit your everyday needs.</span>
                    Perfect for first-time users and crypto enthusiasts alike, the Standard Plan is your gateway to a
                    smarter, more innovative financial journey.</p>
                <div className={classes.sc_container}>
                    <button className={classes.sc_button}
                        onClick={() => window.open('https://apps.apple.com/tr/app/rival-finance/id6468575208', '_blank')}
                    >Get the app</button>
                    <a href='/compare' style={{ textDecoration: "none", listStyle: "none" }}><span className={classes.sc_link}>Learn more</span></a>
                </div>
            </div>
        </div>
    )
}

export default Component21s;
