import React, { useEffect, useState } from 'react';
import classes from './AboutUsBanner.module.css';
import InternationalBtn from '../../../components/international-btn/InternationalBtn';

const AboutUsBanner = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={classes.about_banner}>
      <div className={classes.absolute}>
        <h2>We Change The Way You do Money</h2>
        <InternationalBtn
          title="Spend your crypto"
          style={{
            color: "#000",
            background: "#fff",
            margin: isMobile ? "auto" : "0",
            display: "block", // Ensures margin auto works properly
          }}
        />
      </div>
    </div>
  );
};

export default AboutUsBanner;
