import React from 'react';
import classes from './Component3s.module.css';

const Component3s = () => {
    const image1 = "https://res.cloudinary.com/dwevyyuwo/image/upload/v1736504890/voupwbmjb383byzxk9gs.avif";
    const image2 = "https://res.cloudinary.com/dwevyyuwo/image/upload/v1736505078/kwnibdsbzgza6lcwdt0v.avif";
    const image3 = "https://res.cloudinary.com/dwevyyuwo/image/upload/v1737468552/fullPro_s0tv7t.png";
    return (
        <>
            <div className={classes.parent}>
                <img src={image1} alt="mobile" />
                <div className={classes.child}>
                    <h4 style={{ color: "#07005B" }}>Standard</h4>
                    <p>Unlock the future of finance with Rival's Standard Plan for just $6/month! <span>Dive into the world
                        of seamless payments with a card that makes spending as easy as cash. Say goodbye to complicated
                        processes and hello to secure, hassle-free transactions designed to fit your everyday needs.</span>
                        Perfect for first-time users and crypto enthusiasts alike, the Standard Plan is your gateway to a
                        smarter, more innovative financial journey.</p>
                    <div className={classes.sc_container}>
                        <button className={classes.sc_button}
                            onClick={() => window.open('https://apps.apple.com/tr/app/rival-finance/id6468575208', '_blank')}
                        >Get the app</button>
                        <a href='/compare' style={{ textDecoration: "none", listStyle: "none" }}><span className={classes.sc_link}>Learn more</span></a>
                    </div>
                </div>
            </div>
            <div className={classes.parent}>
                <img src={image2} alt="mobile" />
                <div className={classes.child}>
                    <h4 style={{ color: "#246BFD" }}>Plus</h4>
                    <p>Elevate your everyday spending with Rival’s Plus Plan for just $10/month. <span>Enjoy exclusive access to a card for seamless payments, </span>
                        Whether through Apple Pay, Google Pay, or your own physical card. <span>With dedicated account manager support, the Plus Plan is your
                            key to effortless and innovative financial freedom.</span></p>
                    <div className={classes.sc_container}>
                        <button className={classes.sc_button}
                            onClick={() => window.open('https://apps.apple.com/tr/app/rival-finance/id6468575208', '_blank')}
                        >Get the app</button>
                        <a href='/compare' style={{ textDecoration: "none", listStyle: "none" }}><span className={classes.sc_link}>Learn more</span></a>
                    </div>
                </div>
            </div>
            <div className={classes.parent}>
                <img src={image3} alt="mobile" />
                <div className={classes.child}>
                    <h4 style={{ color: "#D8051A" }}>Pro</h4>
                    <p>Unlock ultimate financial flexibility with Rival’s Pro Plan for $13/month. <span>Get a card for seamless spending, access IBANs
                        for global transactions, and enjoy 24/7 support from dedicated account managers. The Pro Plan is designed for those who
                        demand premium features and unparalleled convenience.</span> Upgrade now and take your finances to the next level!</p>
                    <div className={classes.sc_container}>
                        <button className={classes.sc_button}
                            onClick={() => window.open('https://apps.apple.com/tr/app/rival-finance/id6468575208', '_blank')}
                        >Get the app</button>
                        <a href='/compare' style={{ textDecoration: "none", listStyle: "none" }}><span className={classes.sc_link}>Learn more</span></a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Component3s;
