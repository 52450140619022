import React, { useState, useRef, useContext } from "react";
import classes from "./Component1.module.css";
import { FaPlay, FaPause } from "react-icons/fa";
import forest from '../../asset-video/forest.mp4';

const Component1 = () => {
  const [isPlaying, setIsPlaying] = useState(true);

  const videoRef = useRef(null);

  const togglePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };
  return (
    <div className={classes.home_container1_parent}>
      <video
        controls={false}
        autoPlay
        loop
        muted
        ref={videoRef}
        playsInline
        className={classes.video}
        style={{
          outline: 'none',
          pointerEvents: 'none'
        }}
      >
        <source
          src="https://res.cloudinary.com/dwevyyuwo/video/upload/v1736321742/zu61rcyiyhcxtgios7el.mp4"
          // loading="lazy"
          type="video/mp4"
        />
      </video>
      <div className={classes.content}>
        <p>
          Next generation finance is here – <span style={{ color: "gray" }}>delivering unmatched speed,
            security, and innovation to elevate your financial experience.</span>
        </p>
        <button
          onClick={() =>
            window.open(
              "https://apps.apple.com/tr/app/rival-finance/id6468575208",
              "_blank"
            )
          }
          style={{ cursor: "pointer" }}
        >
          Get In Touch
        </button>
        {/* <div className={classes.playPauseIcon} onClick={togglePlayPause}>
          {isPlaying ? <FaPause /> : <FaPlay />}
        </div> */}
      </div>
    </div>
  );
};

export default Component1;
