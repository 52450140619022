import React, { useState, useEffect } from 'react';
import classes from './Component6.module.css';

import SimpleAnimation from '../../components/simple-animation/SimpleAnimation';
import vdo from '../../asset-video/mobile-coin.mp4';

const Component6 = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className={classes.component6_parent}>
      <div className={classes.component6_texts}>
        <label className={classes.component6_text1}>Investment</label>
        <p className={classes.component6_text2}>Invest with Rival</p>
      </div>
      {!isMobile ? <SimpleAnimation data={"https://res.cloudinary.com/dwevyyuwo/video/upload/v1736244818/Test/suhoydddbhykydghcbn9.mp4"} style={{ position: 'relative', zIndex: 1, marginBottom: '-6px' }} loop={true} /> : null}
      {isMobile ? <SimpleAnimation data={vdo} style={{ position: 'relative', zIndex: 1, marginBottom: '-6px' }} loop={true} /> : null}
      {/* <SimpleAnimation data={vdo} style={{ position: 'relative', zIndex: 1, marginBottom: '-6px' }} loop={true} /> */}
      {/* <SimpleAnimation data={"https://res.cloudinary.com/dwevyyuwo/video/upload/v1736244818/Test/suhoydddbhykydghcbn9.mp4"} style={{ position: 'relative', zIndex: 1, marginBottom: '-6px' }} loop={true} /> */}
    </div>
  )
}

export default Component6;
