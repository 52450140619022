import React, { useEffect, useState } from "react";
import classes from "./Component11.module.css";

import SimpleAnimation from "../../components/simple-animation/SimpleAnimation";

const Component11 = () => {
  const [textsVisible, setTextsVisible] = useState(false);
  const [buttonVisible, setButtonVisible] = useState(false);

  useEffect(() => {
    const textsTarget = document.querySelector(`.${classes.component11_texts}`);

    const textsObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setTimeout(() => setTextsVisible(true), 8000);
          textsObserver.disconnect();
        }
      },
      { threshold: 0.5 }
    );

    if (textsTarget) textsObserver.observe(textsTarget);

    return () => {
      if (textsTarget) textsObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const buttonTarget = document.querySelector(`.${classes.button}`);

    const buttonObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setTimeout(() => setButtonVisible(true), 8000);
          buttonObserver.disconnect();
        }
      },
      { threshold: 0.5 }
    );

    if (buttonTarget) buttonObserver.observe(buttonTarget);

    return () => {
      if (buttonTarget) buttonObserver.disconnect();
    };
  }, []);

  return (
    <div className={classes.component11_parent}>
      <div
        className={`${classes.component11_texts} ${textsVisible ? classes.visible : ""
          }`}
      >
        {/* <p className={classes.component11_text2}>Designed for you</p> */}
      </div>
      <div style={{}}>


        <button
          className={`${classes.button} ${buttonVisible ? classes.btn_visible : ""
            }`}
          onClick={() =>
            window.open(
              "https://apps.apple.com/tr/app/rival-finance/id6468575208",
              "_blank"
            )
          }
        >
          Compare plans
        </button>

        <SimpleAnimation
          data={"https://res.cloudinary.com/dwevyyuwo/video/upload/v1736243914/Test/hx1mwulmwcrzrtbo5jsj.mp4"}
          style={{}}
          className={classes.cardVideo}
          loop={false}
        />
      </div>
    </div>
  );
};

export default Component11;
