import React from 'react';
import classes from './PrivacySecurity.module.css';

import PrivacySecurityChild from '../privacy-security-child/PrivacySecurityChild';

const PrivacySecurity = ({ title, img1, img2, img3, desc1, desc2, desc3 }) => {
  return (
    <div className={classes.privacy_security_parent}>
      <h2>{title}</h2>
      <PrivacySecurityChild desc1={desc1} desc2={desc2} desc3={desc3} img1={img1} img2={img2} img3={img3} />
    </div>
  )
}

export default PrivacySecurity;
