import React, { useState, useEffect, useRef } from 'react';
import classes from './MoneyAbroad.module.css';
import InternationalBtn from '../../../components/international-btn/InternationalBtn';

import cn1 from '../../../asset/coin/cn1.png';
import cn2 from '../../../asset/coin/cn2.png';
import cn3 from '../../../asset/coin/cn3.svg';
import cn4 from '../../../asset/coin/cn4.svg';
import cn5 from '../../../asset/coin/cn5.svg';
import cn6 from '../../../asset/coin/cn6.png';
import cn7 from '../../../asset/coin/cn7.svg';
import cn8 from '../../../asset/coin/cn8.svg';
import cn9 from '../../../asset/coin/cn9.svg';
import cn10 from '../../../asset/coin/cn10.svg';
import cn11 from '../../../asset/coin/cn11.svg';
import cn12 from '../../../asset/coin/cn12.svg';
import cn13 from '../../../asset/coin/cn13.png';
import cn14 from '../../../asset/coin/cn14.png';
import cn15 from '../../../asset/coin/cn15.png';

const MoneyAbroad = () => {
    const [isVisible, setIsVisible] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.5 }
        );

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, []);

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return (
        <div className={classes.money_abroad_parent}>
            <div
                ref={containerRef}
                style={{
                    position: 'relative',
                    transform: isMobile ? "none" : isVisible ? 'translateY(0)' : 'translateY(20px)',
                    opacity: isMobile ? 1 : isVisible ? 1 : 0,
                    transition: isMobile ? "none" : 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
                }} className={classes.money_abroad_main}>
                <h2>So many ways to send money abroad</h2>
                <h5>Popular tokens available</h5>
                <p><label>Buy 20+</label> of the most popular cryptocurrencies in any of the <label>10+ networks available without any fees</label></p>
                <div className={classes.first_row}>
                    <MoneyAbroadChild coin={cn12} desc="Buy Solona" />
                    <MoneyAbroadChild coin={cn7} desc="Buy Polkadot" />
                    <MoneyAbroadChild coin={cn9} desc="Buy Etherium" />
                    <MoneyAbroadChild coin={cn10} desc="Buy Jasmycoin" />
                    <MoneyAbroadChild coin={cn15} desc="Buy USDT" />
                    <MoneyAbroadChild coin={cn8} desc="Buy USDC" />
                </div>
                <div className={classes.first_row}>
                    <MoneyAbroadChild coin={cn5} desc="Buy Cronos" />
                    <MoneyAbroadChild coin={cn14} desc="Buy OMG Network" />
                    <MoneyAbroadChild coin={cn11} desc="Buy Litecoin" />
                    <MoneyAbroadChild coin={cn4} desc="Buy Bitcoin" />
                    <MoneyAbroadChild coin={cn3} desc="Buy Cosmos" />
                </div>
                <div className={classes.first_row}>
                    <MoneyAbroadChild coin={cn13} desc="Buy Elrond" />
                    <MoneyAbroadChild coin={cn2} desc="Buy Cardano" />
                    <MoneyAbroadChild coin={cn6} desc="Buy dogecoin" />
                    <MoneyAbroadChild coin={cn1} desc="Buy 1INCH" />
                    <MoneyAbroadChild onPress={() => window.open('https://apps.apple.com/tr/app/rival-finance/id6468575208', '_blank')} desc="Buy Many more +" style={{ border: "1px solid #246BFD82", background: "#246BFD0D" }} />
                </div>
                <InternationalBtn title="Send Crypto" style={{ margin: "2rem 0 2rem 0" }} />
            </div>
        </div>
    )
}

const MoneyAbroadChild = ({ coin, desc, style, onPress }) => {

    const handleClick = () => {
        if (desc === "Buy Many more +") {
            onPress(); // Call the onPress function passed as a prop
        }
    };

    return (
        <div onClick={handleClick} className={classes.abroad_child} style={style}>
            {coin ? <img src={coin} alt={desc} style={{ fontSize: "40px", height: "auto" }} /> : ''}
            {/* <img src={coin} alt={desc} style={{ fontSize: "40px", height: "auto" }} /> */}
            <p>{desc}</p>
        </div>
    )
}



export default MoneyAbroad;