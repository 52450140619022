import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "../../Context/AuthContext";
import Component1 from "../../build-component/component1/Component1";
import Component2 from "../../build-component/component2/Component2";
import Component3 from "../../build-component/component3/Component3";
import Component4 from "../../build-component/component4/Component4";
import Component5 from "../../build-component/component5/Component5";
import Component6 from "../../build-component/component6/Component6";
import Component24 from "../../build-component/component24/Component24";

import Component1s from "../../build-component/component1s/Component1s";
import Component3s from "../../build-component/component3s/Component3s";

import { Homes } from "../../Track";

const Home = () => {
  useEffect(() => {
    Homes();
  }, []);
  const { handlePopoverClose } = useContext(MyContext);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div onMouseEnter={handlePopoverClose}>
      {!isMobile ? <Component1 /> : null}
      {isMobile ? <Component1s /> : null}
      {!isMobile ? <Component2 /> : null}
      {!isMobile ? <Component3 /> : null}
      {isMobile ? <Component3s /> : null}
      <Component4 />
      <Component5 />
      <Component6 />
    </div>
  );
};

export default Home;
