import React, { useContext, useEffect } from "react";
import { MyContext } from "../../Context/AuthContext";

import Component6 from "../../build-component/component6/Component6";
import Component14 from "../../build-component/component14/Component14";
import Component17 from "../../build-component/component17/Component17";
import InvestingToggle from "../../components/investing-toggle/InvestingToggle";
import Component9 from "../../build-component/component9/Component9";
import Component22 from "../../build-component/component22/Component22";
import Component23 from "../../build-component/component23/Component23";
import PrivacySecurity from "../../components/privacy-security/PrivacySecurity";

import shield from "../../asset/privacy-shield.png";
import circle from "../../asset/privacy-circle.png";
import adjust from "../../asset/privacy-adjust.png";
import lock from "../../asset/security-lock.png";
import alien from "../../asset/security-alien.png";
import thumb from "../../asset/security-thumb.png";
import Component4 from "../../build-component/component4/Component4";
import { Investings } from "../../Track";
import classes from "./Investing.module.css"
const Investing = () => {
  const { handlePopoverClose } = useContext(MyContext);
  useEffect(() => {
    Investings();
  }, []);
  return (
    <div onMouseEnter={handlePopoverClose}>
      <Component6 />
      <Component22 />
      <div className={classes.banner_blank}></div>
      <Component23 />
      <div className={classes.banner_blank2}>

</div>
      <Component4 />
      {/* <Component14 /> */}
      <Component17 />
      <InvestingToggle />
      <Component9 />
      <PrivacySecurity
        title="Privacy"
        desc1="<span>Rival is committed to safeguarding your privacy with ISO-200071 certification and GDPR compliance.</span> We ensure 
      that your personal and financial information is securely stored and never shared without your explicit consent."
        desc2="<span>At Rival, we value transparency. Your data is used solely to enhance your experience and deliver our services.</span> 
      We never sell or misuse your personal information, adhering strictly to global privacy standards."
        desc3="<span>Rival empowers you to take control of your privacy.</span> Manage your personal data, review permissions, and update 
      preferences directly through the app, ensuring a seamless and secure experience."
        img1={shield}
        img2={circle}
        img3={adjust}
      />
      <PrivacySecurity
        title="Security"
        desc1="Rival employs state-of-the-art encryption, passcode verification, and biometric authentication to safeguard your account. 
        <span>Our systems monitor real-time transactions to ensure your funds and personal information are always secure.</span>"
        desc2="<span>Rival's fraud detection systems utilize advanced machine learning to identify and mitigate suspicious activities.</span> Our 
        dedicated fraud prevention team conducts in-depth investigations to protect your account and ensure a secure financial experience."
        desc3="<span>Rival prioritizes your privacy and data security, adhering to ISO-200071 standards and GDPR compliance.</span> We never compromise on 
        protecting your personal information and ensure that all transactions are conducted in a secure environment."
        img1={lock}
        img2={alien}
        img3={thumb}
      />
    </div>
  );
};

export default Investing;
