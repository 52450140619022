import React from 'react';
import classes from './Component26s.module.css';
import InternationalBtn from '../../components/international-btn/InternationalBtn';
import vdo from '../../asset-video/globee.mp4';

const Component26s = () => {
    return (
        <div className={classes.parent}>
            <video
                // className={classes.vdo}
                // key={data}
                muted
                playsInline
                autoPlay
                loop={true}
                style={{
                    outline: 'none',
                    // height: '65vh',
                    pointerEvents: 'none',
                    width: "100%",
                    height: 'auto',
                }}
            >
                <source src={vdo} type="video/mp4" />
            </video>
            <h2>Spend and send your<br /> crypto globally</h2>
            <p>Now with Rival crypto you can send, spend and acquire crypto as easy as any other currency</p>
            <InternationalBtn title="Spend Now" />
        </div>
    )
}

export default Component26s;