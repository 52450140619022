import React from 'react';
import classes from './Component24s.module.css';

const Component24s = () => {
    const image1 = "https://res.cloudinary.com/dwevyyuwo/image/upload/v1736504352/fmjbql6exet0mzwukazm.avif";

    const image2 = "https://res.cloudinary.com/dwevyyuwo/image/upload/v1736504360/eyqz4qw2sbhibzetoifv.avif"

    const image3 = "https://res.cloudinary.com/dwevyyuwo/image/upload/v1736504367/eed6cktcphsbxi4ezluy.avif"
    return (
        <>
            <div className={classes.parent}>
                <img src={image1} alt="mobile" />
                <div className={classes.child}>
                    <h4>Current Account</h4>
                    <p>Rival’s current account is your <span>central hub</span> for managing all your funds in one place. Whether transferring
                        money to your card account, making <span>global transfers,</span> or connecting to the crypto world, Rival simplifies
                        every transaction with <span>ease</span> and <span>transparency.</span></p>
                </div>
            </div>
            <div className={classes.parent}>
                <img src={image2} alt="mobile" />
                <div className={classes.child}>
                    <h4>Transfer</h4>
                    <p>Elevate your everyday spending with Rival’s Plus Plan for just $10/month. <span>Enjoy exclusive access to a card for seamless payments, </span>
                        Whether through Apple Pay, Google Pay, or your own physical card. <span>With dedicated account manager support, the Plus Plan is your
                            key to effortless and innovative financial freedom.</span></p>
                </div>
            </div>
            <div className={classes.parent} style={{ paddingBottom: "3.3rem" }}>
                <img src={image3} alt="mobile" />
                <div className={classes.child}>
                    <h4>View Card</h4>
                    <p>Unlock ultimate financial flexibility with Rival’s Pro Plan for $13/month. <span>Get a card for seamless spending, access IBANs
                        for global transactions, and enjoy 24/7 support from dedicated account managers. The Pro Plan is designed for those who
                        demand premium features and unparalleled convenience.</span> Upgrade now and take your finances to the next level!</p>
                </div>
            </div>
        </>
    )
}

export default Component24s;