import React from 'react';
import classes from './Component1s.module.css';
import SimpleAnimation from '../../components/simple-animation/SimpleAnimation';
import vdo from '../../asset-video/mobile-main.mp4';
import data from '../../asset-video/new-saving-video.mp4';
import InternationalBtn from '../../components/international-btn/InternationalBtn';

const Component1s = () => {
    return (
        <div className={classes.parent}>
            <div className={classes.child1}>
                <p>Next generation finance is here – <span>delivering unmatched speed, security, and innovation to elevate your financial experience.</span></p>
                <button
                    onClick={() =>
                        window.open(
                            "https://apps.apple.com/tr/app/rival-finance/id6468575208",
                            "_blank"
                        )
                    }
                    style={{ cursor: "pointer" }}
                >
                    Get the app
                </button>
            </div>
            <video
                key={data}
                muted
                playsInline
                autoPlay
                loop={true}
                className={classes.child2}
            >
                <source src={data} type="video/mp4" />
            </video>
            <div className={classes.child3}>
                <h4>Spend, Send, and Invest</h4>
                <p>Rival simplifies your finances<label>—with your Rival card, send low-cost remittances, and invest in more than 1000 stocks and cryptocurrencies, all in one secure platform.</label></p>
                <InternationalBtn title="Get the app" />
            </div>
            <SimpleAnimation data={vdo} style={{ position: 'relative', zIndex: 1, marginBottom: '-6px' }} loop={true} />
        </div>
    )
}

export default Component1s;