import React from 'react';
import classes from './CryptoTexts.module.css';
import InternationalBtn from '../../../components/international-btn/InternationalBtn';

const CryptoTexts = () => {
    return (
        <div className={classes.crypto_texts}>
            <h3>Spend your stablecoins</h3>
            <p>With Rival’s crypto card, use stablecoins like USDT and USDC effortlessly, with 1 USDT = $1 = 3.67 AED. <span>Enjoy simple, secure payments for everyday spending, online or in-store.</span></p>
            <InternationalBtn title="Spend your crypto" style={{ marginBottom: "30px" }} />
        </div>
    )
}

export default CryptoTexts;