import React from 'react';
import classes from './Component7.module.css'

const Component7 = () => {
  return (
    <div className={classes.parent_p}>
      <div className={classes.parent}>
        <div className={classes.left}>
          <p className={classes.para} style={{ marginBottom: "40px" }}>Rival’s current account offers a secure, USD-based platform for managing your money globally.
            With <label>instant transfers</label> between users, multiple funding options including <label>crypto</label> and <label>bank transfers,</label> and seamless
            integration with your <label>Rival cards</label>, it’s designed for convenience and transparency. Backed by advanced security and <label>no
              hidden fees,</label> Rival’s current account is your all-in-one solution for <label>spending, saving,</label> and <label>transferring</label> funds effortlessly.</p>

          <p className={classes.para}>Rival removes global boundaries by enabling seamless, borderless
            financial transactions, empowering users to send, spend, and manage money effortlessly worldwide.</p>
        </div>
        <div className={classes.right}>
          <h3 style={{ marginBottom: "40px" }} className={classes.heading}>Empowering your Money. <span>One dollar at a time.</span></h3>
          <p className={classes.para}>We aim to <label>empower</label> users with seamless, secure, and <label>affordable</label> tools that simplify
            <label>global transactions,</label> foster financial inclusion, and embrace the future of <label>stablecoins.</label> At Rival, our focus
            is on innovation, transparency, and making finance <label>accessible</label> to <label>everyone.</label></p>

        </div>
      </div>
    </div>
  )
}

export default Component7