
// import React, { useState, useRef } from 'react';

// const SimpleAnimation = ({ data, style, loop }) => {
//   const [hasPlayedOnce, setHasPlayedOnce] = useState(false);
//   const videoRef = useRef(null);

//   const handleVideoEnd = () => {
//     setHasPlayedOnce(true);
//     videoRef.current.pause();
//   };

//   return (
//     <div style={style}>
//       <video
//         width="100%"
//         height="auto"
//         ref={videoRef}
//         key={data}
//         muted
//         onEnded={handleVideoEnd}
//         style={{ outline: 'none' }}
//         autoPlay
//         loop={loop}
//         // controls={true}
//       >
//         <source src={data} type="video/mp4" />
//       </video>
//     </div>
//   );
// };

// export default SimpleAnimation;

import React, { useState, useRef } from 'react';

const SimpleAnimation = ({ data, style, loop }) => {
  const [hasPlayedOnce, setHasPlayedOnce] = useState(false);
  const videoRef = useRef(null);

  const handleVideoEnd = () => {
    setHasPlayedOnce(true);
    videoRef.current.pause();
  };

  return (
    <div style={{ ...style, position: 'relative' }}>
      <video
        width="100%"
        height="auto"
        ref={videoRef}
        key={data}
        muted
        onEnded={handleVideoEnd}
        autoPlay
        loop={loop}
        playsInline // Ensure compatibility for iOS devices
        style={{
          outline: 'none',
          pointerEvents: 'none', // Prevent interaction
          marginTop: '-5px'
        }}
      >
        <source src={data} type="video/mp4" />
      </video>
    </div>
  );
};

export default SimpleAnimation;
