import React, { useState, useEffect, useRef } from 'react';
import classes from './CryptoInfo.module.css';

import house from '../../../asset/house.png';
import arrowCurve from '../../../asset/arrow-curve.png';
import jupiter from '../../../asset/jupiter.png';
import scan from '../../../asset/scan.png';
import gpay from '../../../asset/gpay.png';
import apay from '../../../asset/apay.png';

const CryptoInfo = () => {
    const [isVisible, setIsVisible] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.5 }
        );

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, []);


    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return (
        <div className={classes.info_parent}>
            <div className={classes.info_main}>
                <h2>Choose the best way to spend, transfer and acquire</h2>
                <p>We are making crypto and fiat accessible for all</p>
                <div className={classes.info_containers}>
                    <div
                        ref={containerRef}
                        style={{
                            position: 'relative',
                            transform: isMobile ? 'none' : isVisible ? 'translateY(0)' : 'translateY(20px)',
                            opacity: isMobile ? 1 : isVisible ? 1 : 0,
                            transition: isMobile ? 'none' : 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
                        }} className={classes.container_left}>
                        <div className={classes.left_first}>
                            <img src={house} alt="house" style={{ width: "100%", height: "auto" }} />
                        </div>
                        <div className={classes.left_second}>
                            <img src={arrowCurve} alt="arrow-curve" style={{ width: "100%", height: "auto" }} />
                        </div>
                        <div className={classes.left_third}>
                            <img src={jupiter} alt="jupiter" style={{ width: "100%", height: "auto" }} />
                        </div>
                    </div>
                    {isMobile ?
                        <div className={classes.texts_left}>
                            <h2>Bank Transfers</h2>
                            <p><label>On-ramp and off ramp into the</label> Crypto world using our IBAN services</p>
                        </div> : null}
                    <div
                        ref={containerRef}
                        style={{
                            position: 'relative',
                            transform: isMobile ? 'none' : isVisible ? 'translateY(0)' : 'translateY(20px)',
                            opacity: isMobile ? 1 : isVisible ? 1 : 0,
                            transition: isMobile ? 'none' : 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
                        }} className={classes.container_right}>
                        <div className={classes.right_left}>
                            <img src={scan} alt="scan" style={{ width: "100%", height: "auto" }} />
                        </div>
                        <div className={classes.right_right}>
                            <div className={classes.right_right_top}>
                                <img src={apay} alt="apay" style={{ width: "110px", height: "auto" }} />
                            </div>
                            <div className={classes.right_right_bottom}>
                                <img src={gpay} alt="gpay" style={{ width: "110px", height: "auto" }} />

                            </div>
                        </div>
                    </div>
                    {isMobile ?
                        <div className={classes.texts_right}>
                            <h2>Apple Pay and Google Pay</h2>
                            <p><label>Enjoy the convenience of spending your crypto anywhere and everywhere in the world, this includes adding your</label> Rival crypto card to Apple Pay and Google Pay</p>
                        </div> : null}
                </div>
                {!isMobile ?
                    <div className={classes.texts_container}>
                        <div className={classes.texts_left}>
                            <h2>Bank Transfers</h2>
                            <p><label>On-ramp and off ramp into the</label> Crypto world using our IBAN services</p>
                        </div>
                        <div className={classes.texts_right}>
                            <h2>Apple Pay and Google Pay</h2>
                            <p><label>Enjoy the convenience of spending your crypto anywhere and everywhere in the world, this includes adding your</label> Rival crypto card to Apple Pay and Google Pay</p>
                        </div>
                    </div>
                    : null}
            </div>
        </div>
    )
}

export default CryptoInfo