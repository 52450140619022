import React, { useState, useEffect, useRef } from "react";
import classes from "./CryptoSections.module.css";

import hexagon from "../../../asset/scanner.png";
import tCoin from "../../../asset/coinMirror.png";
import phone from "../../../asset/half-phone.png";

const CryptoSections = () => {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className={classes.crypto_sections}>
      <div className={classes.section_main}>
        <h2>Your Keys, Your Coins. Simplified.</h2>
        <p>Rival is your gateway to the future of finance. With account abstraction, we’ve eliminated
          the complexity, giving you all the benefits without the hassle.</p>
        <div className={classes.three_sections}>
          <div
            ref={containerRef}
            style={{
              position: "relative",
              transform: isMobile ? "none" : isVisible ? "translateY(0)" : "translateY(20px)",
              opacity: isMobile ? 1 : isVisible ? 1 : 0,
              transition: isMobile ? "none" : "transform 0.5s ease-in-out, opacity 0.5s ease-in-out",
            }}
            className={classes.first_section}
          >
            <h3>Smart money</h3>
            <img
              src={tCoin}
              alt=""
              style={{ width: "80%", height: "auto", margin: "4rem 0 6rem 0" }}
            />

            <p>
              Spend your crypto with 365, 24/7 ISO certified security and
              privacyCompare Plans
            </p>

            <a href="/compare" style={{ textDecoration: "none", listStyle: "none", cursor: "pointer" }}><h5>Compare Plans</h5></a>
          </div>
          <div
            ref={containerRef}
            style={{
              position: "relative",
              transform: isMobile ? "none" : isVisible ? "translateY(0)" : "translateY(20px)",
              opacity: isMobile ? 1 : isVisible ? 1 : 0,
              transition: isMobile ? "none" : "transform 0.5s ease-in-out, opacity 0.5s ease-in-out",
            }}
            className={classes.second_section}
          >
            <h3>Pay and get card</h3>
            <img
              src={phone}
              alt=""
              style={{ width: "80%", height: "auto", marginBottom: "30px" }}
            />
            <p>Pay with crypto and get your rival<br></br> card</p>
          </div>
          <div
            ref={containerRef}
            style={{
              position: "relative",
              transform: isMobile ? "none" : isVisible ? "translateY(0)" : "translateY(20px)",
              opacity: isMobile ? 1 : isVisible ? 1 : 0,
              transition: isMobile ? "none" : "transform 0.5s ease-in-out, opacity 0.5s ease-in-out",
            }}
            className={classes.third_section}
          >
            <h3>No seed phrase</h3>
            <img
              src={hexagon}
              alt=""
              style={{ width: "80%", height: "auto", margin: "4rem 0" }}
            />
            <p>Just need your phone,<br></br>your email, and you are good <br></br>to go.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CryptoSections;
