import React, { useEffect, useState } from "react";
import classes from "./AboutUsSimplify.module.css";
import data from "../../../asset-video/CoinsAnimation.mp4";

const AboutUsSimplify = () => {
   const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
      
        useEffect(() => {
          const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
      
          window.addEventListener("resize", handleResize);
          return () => {
            window.removeEventListener("resize", handleResize);
          };
        }, []);
      
  return (
    <div className={classes.parent}>
      <h2>Let's simplify all things money</h2>
      <p>
        Our global mission is for every person and business to do all things
        money — spending, saving, investing, borrowing, managing, and more — in
        just a few taps.
      </p>

      <video
  width="50%"
  height="auto"
  key={data}
  muted
  playsInline
  style={{
    outline: "none",
    pointerEvents: "none",
    transform: `scale(1.0) rotate(${isMobile ? "90deg" : "0deg"})`,
    transition: "transform 0.5s ease-in-out",
    marginTop: isMobile ? "50px" : "0px", 
    width: isMobile ? "100%" : "50%",
    height: isMobile ? "350px" : "auto", // Adjust height for mobile
    
  }}
  autoPlay
  loop
>
  <source src={data} type="video/mp4" />
</video>

    </div>
  );
};

export default AboutUsSimplify;
