import React from 'react';
import classes from './CryptoBannerS.module.css';
import SimpleAnimation from '../../../components/simple-animation/SimpleAnimation';
import vdo from '../../../asset-video/mobile-crypto.mp4';
import InternationalBtn from '../../../components/international-btn/InternationalBtn';

const CryptoBannerS = () => {
    return (
        <div className={classes.parent}>
            <div className={classes.main}>
                <h2>The First and Best<br /> Crypto Card</h2>
                <p>The First and Only Active Crypto Card in the<br /> Middle East and UAE*</p>
                <InternationalBtn title="Spend in crypto" />
            </div>
            <SimpleAnimation data={vdo} style={{ position: 'relative', zIndex: 1, marginBottom: '-6px' }} loop={true} />
        </div>
    )
}

export default CryptoBannerS;