import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "../../Context/AuthContext";

import CryptoBanner from "../../build-component/crypto-section/crypto-banner/CryptoBanner";
import CryptoInfo from "../../build-component/crypto-section/crypto-info/CryptoInfo";
import CryptoSections from "../../build-component/crypto-section/crypto-sections/CryptoSections";
import MoneyAbroad from "../../build-component/crypto-section/money-abroad/MoneyAbroad";
import CryptoInstantly from "../../build-component/crypto-section/crypto-instantly/CryptoInstantly";
import CryptoTexts from "../../build-component/crypto-section/crypto-texts/CryptoTexts";
import SimpleAnimation from "../../components/simple-animation/SimpleAnimation";
import CryptoToggle from "../../components/crypto-toggle/CryptoToggle";
import Component26 from "../../build-component/component26/Component26";

import CryptoBannerS from "../../build-component/crypto-section/crypto-banner-s/CryptoBannerS";
import Component26s from "../../build-component/component26s/Component26s";

import { Cryptos } from "../../Track";
import coins from "../../asset-video/crypto-new-coin.mp4";

const Crypto = () => {
  const { handlePopoverClose } = useContext(MyContext);
  useEffect(() => {
    Cryptos();
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div onMouseEnter={handlePopoverClose}>
      {isMobile ? <CryptoBannerS /> : null}
      {!isMobile ? <CryptoBanner /> : null}
      <CryptoInfo />
      <CryptoTexts />
      <SimpleAnimation data={coins} style={{ marginBottom: "-5px" }} />
      <MoneyAbroad />
      {isMobile ? <Component26s /> : null}
      {!isMobile ? <Component26 /> : null}
      <CryptoSections />
      <CryptoToggle />
    </div>
  );
};

export default Crypto;
