import React, { useEffect, useState } from "react";
import classes from "./Component25.module.css";
import mobile from "../../asset-video/globee.mp4"

const Component25 = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const data =
    "https://res.cloudinary.com/dwevyyuwo/video/upload/v1736510797/kwdzeke7kdqvogyy31b3.mp4";
  return (
    <div className={classes.parent_container}>
      <div className={classes.absolute_container}>
        <label>Overseas without the overspend</label>
        <h3>
          INTERNATIONAL<br></br> MONEY TRANSFER
        </h3>
        <p>
          Rival offers fast, secure, and affordable money transfers worldwide.
          With instant<br></br> Rival-to-Rival transfers, transparent fees, and
          multiple funding options,<br></br> including crypto and bank
          transfers, we make global transactions seamless and<br></br> reliable.
          Experience the future of money transfers with Rival.
        </p>
      </div>
      <div className={classes.left_container}>
        <div className={classes.left_container_child}>
          <label>Overseas without the overspend</label>
          <h3>INTERNATIONAL MONEY TRANSFER</h3>
          <p>
            Rival offers fast, secure, and affordable money transfers worldwide.
            With instant Rival-to-Rival transfers, transparent fees, and
            multiple funding options, including crypto and bank transfers, we
            make global transactions seamless and reliable. Experience the
            future of money transfers with Rival.
          </p>
        </div>
      </div>
        <div className={classes.right_container}>
          <video
            loading="lazy"
            width="100%"
            height="auto"
            key={isMobile ? mobile : data}
            muted
            playsInline
            style={{
              outline: "none",
              pointerEvents: "none", 
            }}
            autoPlay
            loop={false}
            className={classes.vdo}
          >
            <source src={isMobile ? mobile : data} type="video/mp4" />
          </video>
        </div>
 
    </div>
  );
};

export default Component25;

