import React from "react";
import classes from "./Component26.module.css";
import InternationalBtn from '../../components/international-btn/InternationalBtn';
// import data from '../../asset-video/earth.mp4';

const Component26 = () => {
    const data =
        "https://res.cloudinary.com/dwevyyuwo/video/upload/v1736510797/kwdzeke7kdqvogyy31b3.mp4";
    return (
        <div className={classes.parent_container}>
            {/* <div className={classes.left_container}>
      </div> */}
            <div className={classes.absolute_container}>
                <h3>Spend and send<br></br> your crypto globally</h3>
                <p>
                    Now with Rival crypto you can send, spend and<br></br> acquire crypto as easy as any other currency

                </p>
                <InternationalBtn title="Spend Now" />
            </div>
            <div className={classes.left_container}>
                <div className={classes.left_container_child}>
                    <h3>Spend and send your crypto globally</h3>
                    <p>
                        Now with Rival crypto you can send, spend and acquire crypto as easy as any other currency

                    </p>
                    <InternationalBtn title="Spend Now" />
                </div>
            </div>
            <div className={classes.right_container}>
                <video
                    loading="lazy"
                    width="100%"
                    height="auto"
                    key={data}
                    muted
                    playsInline
                    style={{
                        outline: "none",
                        pointerEvents: "none", // Prevent interaction
                    }}
                    autoPlay
                    loop={false}
                    className={classes.vdo}
                >
                    <source src={data} type="video/mp4" />
                </video>
            </div>
        </div>
    );
};

export default Component26;
