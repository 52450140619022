import React, { useState, useEffect, useRef } from 'react';
import classes from './CardDevices.module.css';
import phone from '../../../asset/black-phone.png';
import watch from '../../../asset/blackWa.png';
import card from '../../../asset/rivalBlue.png';

const CardsAgainDevices = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    // Detect screen size
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768); // Adjust breakpoint as needed
    };

    handleResize(); // Check screen size on initial load
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isSmallScreen) {
      // Disable observer on small screens
      setIsVisible(true);
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [isSmallScreen]);

  return (
    <div className={classes.devices_parent}>
      <div className={classes.devices}>
        <div
          ref={containerRef}
          style={{
            position: 'relative',
            transform: isVisible || isSmallScreen ? 'translateY(0)' : 'translateY(20px)',
            opacity: isVisible || isSmallScreen ? 1 : 0,
            transition: isSmallScreen ? 'none' : 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
          }}
          className={classes.device_left}>
          <img src={phone} alt="device_1" />
          <p>
            Rival’s integration with Apple Pay ensures secure, contactless payments worldwide. This partnership
            combines Rival’s innovative financial services with Apple’s trusted technology, offering users a seamless
            and convenient payment experience.
          </p>
        </div>
        <div className={classes.devices_right}>
          <div
            ref={containerRef}
            style={{
              position: 'relative',
              transform: isVisible || isSmallScreen ? 'translateY(0)' : 'translateY(20px)',
              opacity: isVisible || isSmallScreen ? 1 : 0,
              transition: isSmallScreen ? 'none' : 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
            }}
            className={classes.device_right_top}>
            <p>
              Rival’s card is fully compatible with G Pay, allowing you to make secure and effortless
              payments directly from your Watch. Whether you're shopping, dining, or on the go, simply
              tap your wrist to pay using your card. It's fast, secure, and brings the power of fiat and
              crypto spending to your everyday lifestyle.
            </p>
            <img src={watch} alt="watch" />
          </div>
          <div
            ref={containerRef}
            style={{
              position: 'relative',
              transform: isVisible || isSmallScreen ? 'translateY(0)' : 'translateY(20px)',
              opacity: isVisible || isSmallScreen ? 1 : 0,
              transition: isSmallScreen ? 'none' : 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
            }}
            className={classes.device_right_bottom}>
            <p>
              Rival's physical card is your gateway to effortless spending, bridging the gap between
              crypto and traditional payments. Backed by Visa, it allows you to make purchases globally,
              just like any traditional bank card. Whether shopping in-store or withdrawing cash from ATMs,
              the Rival physical card combines security, convenience, and seamless integration with your
              digital assets. Accepted worldwide, it also allows those who want to bring crypto into their
              everyday lives.
            </p>
            <img src={card} alt="cards" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardsAgainDevices;