import React, { useEffect, useState } from "react";
import classes from "./CardAnimation.module.css";
import SimpleAnimation from "../../../components/simple-animation/SimpleAnimation";
import horizontalCard from "../../../asset-video/cardPik.mp4";
import cardImage from "../../../assets/cardRivalBlue.png";

const CardAnimation = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className={classes.card_animation}>
      <div className={classes.card_animation_child}>
        <h2 className={classes.cards_again_card_title1}>
          Cards with so many colors.
        </h2>
        <h2 className={classes.cards_again_card_title2}>
          With so many different choices,Match your card with your style.
        </h2>
        <p className={classes.cards_animation_para}>
          Rival has partnered with Visa Asia to introduce the Rival VISA card, a
          collaboration that, along with contributions from other third parties,
          enables us to engage a diverse user base both locally and globally.
          Our mission at Rival is to provide a borderless, frictionless, and
          limitless financial experience for everyone.
        </p>
      </div>

      {isMobile ? (
        <img src={cardImage} alt="logo" width="70%" height="100%"  className={classes.cardSection2}   style={{ paddingTop: "320px"}} />
      ) : (
        <SimpleAnimation
          data={horizontalCard}
          className={classes.cardSection2}
          loop={false}
          style={{ paddingTop: "38px" }}
        />
      )}
    </div>
  );
};

export default CardAnimation;
